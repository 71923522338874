<div class="sidebar border-right">
  <nav class="sidebar-nav">
    <ul class="nav">
      <ng-container *ngFor="let menuItem of sideBarMenu">
        <li *ngIf="!menuItem.subMenu" class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: false }">
          <a class="nav-link" [routerLink]="menuItem.link" routerLinkActive="active"
             [routerLinkActiveOptions]="{ exact: false }">
            <em class="{{menuItem.linkIcon}}" aria-hidden="true"></em>
            {{ menuItem.linkTranslate | translate }}
          </a>
        </li>
        <app-nav-dropdown *ngIf="menuItem.subMenu" [headerTitle]="menuItem.linkTranslate|translate">
          <li *ngFor="let subItem of menuItem.subMenu" class="nav-item"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }">
            <a class="nav-link" [routerLink]="subItem.link" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: false}">
              <em class="{{subItem.linkIcon}}" aria-hidden="true"></em>
              {{ subItem.linkTranslate|translate }}
            </a>
          </li>
        </app-nav-dropdown>
      </ng-container>
    </ul>

  </nav>
</div>
