import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillingAddressService} from './services/billing-address.service';
import {BillingAddressModel} from './models/billing-address.model';
import {CompanyService} from '../../../../company/services/company.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-billing-address-dropdown',
  templateUrl: './billing-address-dropdown.component.html',
  styleUrls: ['./billing-address-dropdown.component.scss']
})
export class BillingAddressDropdownComponent implements OnInit {
  @Output() change = new EventEmitter<BillingAddressModel>();
  @Input() requestAddressId: string;
  @Input() placeholder: string;
  @Input() companyId: string;
  @Input() active: boolean = false;
  @Input() clearable: boolean = false;
  @Input() searchable: boolean = false;
  billingAddresses: BillingAddressModel[];
  selectedBillingAddress: BillingAddressModel;
  readOnly: boolean;

  constructor(private service: BillingAddressService, private companyService: CompanyService, public translateService: TranslateService) { }

  ngOnInit(): void {
    this.retrieveBillingAddresses();
  }

  retrieveBillingAddresses() {
    this.readOnly = false;
    this.billingAddresses = [];
    this.selectedBillingAddress = null;
    if (this.companyId) {
      this.service.retrieveBillingAddresses(this.companyId, this.active).subscribe({
        next: addresses => {
          this.updateSelectedBillingAddress(addresses);
        }
      });
    }
  }

  updateSelectedBillingAddress(addresses: BillingAddressModel[]) {
    this.companyService.getCompanyById(this.companyId).subscribe({
      next: companyDetails => {
        this.selectedBillingAddress = addresses.find(address => address.id === (this.requestAddressId ? this.requestAddressId : companyDetails.addressId));
        if (this.active) {
          addresses = addresses.filter(address => address.active === this.active || address.id === this.selectedBillingAddress?.id);
        }
        this.billingAddresses = addresses;
        this.change.emit(this.selectedBillingAddress);
        this.readOnly = (this.billingAddresses.length === 1);
      }
    });
  }
}
