import {CompanyTypeEnum} from './company-type.enum';
import {CompanyStatusEnum} from './company-status.enum';
import {CompanyLevelEnum} from './company-level.enum';

export class AdvancedSearchModel {
  name?: string;
  incorporationNumber?: string;
  types?: Array<CompanyTypeEnum>;
  level?: CompanyLevelEnum;
  status?: Array<CompanyStatusEnum>;
  parentName?: string;

  constructor(object?: any) {
    if (object) {
      this.name = object.name;
      this.level = object.level;
      this.incorporationNumber = object.incorporationNumber;
      this.types = object.types;
      this.status = object.status;
      this.parentName = object.parentName;
    }
  }
}
