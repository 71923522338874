<ng-select
  [items]="billingAddresses"
  (change)="change.emit($event)"
  [multiple]="false"
  [closeOnSelect]="true"
  [searchable]="searchable"
  [(ngModel)]="selectedBillingAddress"
  [placeholder]="placeholder|translate"
  [readonly]="readOnly"
  [clearable]="clearable"
  bindLabel="name"
  class="ng-select-custom"
>
  <ng-template ng-option-tmp let-item="item">
    <p class="mb-0">{{ item.name }}{{ item.recipientName ? (' - ' + item.recipientName) : '' }}</p>
    <p class="mb-0 pl-1 text-dark-gray">{{ item.street1 }}</p>
    <p class="mb-0 pl-1 text-dark-gray">
      <span *ngIf="translateService.currentLang === 'FR'">{{item.postCode}} | {{ item.city }}</span>
      <span *ngIf="translateService.currentLang !== 'FR'">{{ item.city }} {{item.stateOrProvince ? (' | ' + item.stateOrProvince) :''}} | {{item.postCode}}</span>
    </p>
    <p class="mb-0 pl-1 text-dark-gray">{{item.country}}</p>
  </ng-template>
  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-disabled ng-star-inserted">
      {{'global.dropdown.not-found-billing-address'|translate}}
    </div>
  </ng-template>
</ng-select>
