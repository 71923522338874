import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDynamicInputWidth]'
})
export class DynamicInputWidthDirective {
  constructor(private el: ElementRef) {
    this.setWidth(); // Set the initial width
  }

  @HostListener('input') onInput() {
    this.setWidth(); // Adjust width on input
  }

  private setWidth() {
    const input = this.el.nativeElement;
    input.style.width = `${input.value.length + 1}ch`; // Set width based on input value length
  }
}
